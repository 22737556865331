const ALL_CONSTANT = {
  NEXT: 'next',
  BACK_TO_DASHBOARD: 'back_to_dashboard',
  START_QUESTION: 'start_question',
  DEFINITION: 'definition',
  SENTENCES: 'sentences',
  QUESTIONS_SMALL: 'questions',
  PROGRESS: 'progress',
  CURRENT_GAME_POSITION: 'currentGamePosition',
  CURRENT_LEVEL: 'currentLevel',
  LEARNING_WORDS: 'learningWords',
  LEVELS_COUNT: 13,
  FETCHING: 'Fetching',
  USERS: 'users',
  WORDS: 'words',
  QUESTIONS: 'questions',
  END_SESSION: 'END SESSION',
  GET_ONE_MORE: 'GET ONE MORE',
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  INFORMATION: 'information',
  SAVING: 'Saving...',
  START_LEARNING: 'START LEARNING',
  SENTENCE: 'sentence',
  SENTENCE_TITLECASE: 'Sentence',
  OPTION: 'option',
  QUESTION: 'question',
  QUESTION_TITLECASE: 'Question',
  WORD: 'word',
  NAME_REQUIRED: 'Name is required!',
  EMAIL_REQUIRED: 'Email is required!',
  FEEDBACK_REQUIRED: 'Feedback is required!',
  WORD_TITLECASE: 'Word',
  UI_DESIGN: 'UI/Design',
  FUNCTIONALITY: 'Functionality',
  OTHER: 'Other',
  LEARN_MORE: 'Learn More',
  DEFAULT_LEARNING_COUNT: 9,
  DEFAULT_LEARNT_COUNT: 2,
  DEFAULT_NEW_COUNT: 2,
  WEB_WORKER_LEVEL: 7,
  TIMEOUT_NUM: 30000,
  USERNAME_MAX_LENGTH: 20,
  USERNAME_MIN_LENGTH: 3,
  DEFAULT_ONE: 1,
  DEFAULT_TWO: 2,
  DEFAULT_THREE: 3,
  DEFAULT_FOUR: 4,
  DEFAULT_FIVE: 5,
  DATA_LIMIT: 3,
  RANDOM_WORD_LIMIT: 10,
  QUESTIONS_LIMIT: 2,
  LEARNT_THRESHOLD: 4,
  GET_WORD_LIMIT: 10,
  RANDOM_LIIMIT: 20,
  FALLEN_SPEED: 100,
  ANIMATION_DELAY: 0.1,
  REMINDER_NUMBER: 2,
  SLOW_AUDIO_SPEED: 0.65,
  NORMAL_SPEED: 1,
  SHOW_MODAL_TIMEOUT: 1000,
  TEXT_TO_SPEECH_BTN_SIZE: 27,
  SIGNING_IN: 'Signing you in',
};

export default ALL_CONSTANT;
