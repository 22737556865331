const CONSTANTS = {
  ALL_WORDS: 'allWords',
  ANTONYM: 'antonym',
  ANTONYMS: 'Antonyms',
  APP_TITLE: 'Shabadavali.',
  BACK: 'Back',
  COINS_DESC: 'Total number of Nanak Coins',
  COINS_DESC2: 'QUESTIONS TO GO FOR YOUR NEXT COIN!!',
  CONFIRM_PASSWORD: 'Confirm Password',
  CREATED_AT: 'Created At',
  DASHBOARD: 'Dashboard',
  DESCRIBE_YOUR_FEEDBACK: 'Describe your feedback',
  DESCRIPTION: 'Made by Khalis Foundation',
  EDIT: 'Edit',
  EMAIL: 'Email',
  EMAIL_VALIDATED: 'Email validated',
  EMAIL_VERIFICATION_SENT: 'Email verification sent to your inbox!',
  ENTER_DETAILS: 'Please enter your details',
  ENTER_VALID_EMAIL: 'Please enter a valid email address',
  ERROR: 'Error',
  ERROR_PWD:
    'Password must contain at least 8 characters, one uppercase, one lowercase and one number',
  EXAMPLES: 'Examples',
  EXPLORE_WORD: 'Explore Word',
  FEEDBACK: 'Feedback',
  FEEDBACK_FORM: 'Feedback Form',
  FEEDBACK_TYPE: 'Feedback Type',
  FIFTH_QUESTION: 'You completed your 5th question!',
  FILL_ALL_FIELDS: 'Please fill all the fields',
  FIX_ERRORS: 'Please fix the errors to proceed',
  GRAY: 'gray',
  GREAT_JOB: 'Great Job!',
  GURFATEH_PUNJABI: 'ਵਾਹਿਗੁਰੂ ਜੀ ਕਾ ਖਾਲਸਾ ॥ ਵਾਹਿਗੁਰੂ ਜੀ ਕੀ ਫਤਿਹ ॥',
  GURMUKHI: 'gurmukhi',
  KNOW_MORE: 'Know More',
  LAST_LOGIN_AT: 'Last Login At',
  LOADING: 'Loading...',
  LOGIN_INFO: `Before you start adding new words to your 
    vocabulary, we just need some information from you.`,
  LOGGING_OUT: 'Logging out...',
  NAME: 'Name',
  NUM_COINS: 13,
  OR: 'or',
  PASSWORD: 'Password',
  PASSWORDS_DONT_MATCH: 'Passwords do not match!',
  PROFILE: 'Profile',
  PROFILE_UPDATED: 'Profile updated!',
  PUNJABI_5: '੫',
  QUESTIONS: 'Questions',
  QUESTIONS_TO_GO: 'QUESTIONS TO GO FOR YOUR NEXT NANAK COIN!!',
  RANDOM_WORD_TITLE: 'Random word of the Day',
  RED: 'red',
  SAVE: 'Save',
  SEMANTICS_PRAISE: 'ਸ਼ਾਬਾਸ਼! (Well done) Ready for the challenges?',
  SETTINGS: 'Settings',
  SIGN_IN: 'Sign In',
  SIGN_IN_WITH_GOOGLE: 'Sign In with Google',
  SIGN_OUT: 'Sign Out',
  SIGN_UP: 'Sign Up',
  SHASHA: 'ਸ਼',
  SSA_PUNJABI: 'ਸਤਿ ਸ਼੍ਰੀ ਅਕਾਲ',
  SUCCESS: 'Success',
  SUBMIT: 'Submit',
  SYNONYM: 'synonym',
  SYNONYMS: 'Synonyms',
  TITLE: 'Shabadavali',
  TOTAL_NUM_QUESTIONS: 13,
  UPDATED_AT: 'Updated At',
  UPLOAD_A_PHOTO: 'Upload a photo',
  USERNAME: 'Username',
  USERNAME_TAKEN: 'Username already taken!',
  USERNAME_TOO_SHORT: 'Username must be at least 3 characters long!',
  USERNAME_TOO_LONG: 'Username must be at most 20 characters long!',
  VERIFY: 'Verify',
  WELCOME: 'Welcome',
  WORD_NOT_FOUND: 'Word not found',
  WORDS_YOU_LEARNT: 'Words you learnt',
  YES: 'Yes',
  YOUR_DETAILS: 'Your Details',
  INVALID_EMAIL: 'Invalid email address.',
  USER_DISABLED: 'User account is disabled.',
  USER_NOT_FOUND: 'No user found with this email.',
  INCORRECT_PASSWORD: 'Incorrect Password.',
  EMAIL_ALREADY_EXISTS: 'An account with this email already exists.',
  INTERNAL_ERROR: 'An internal error occurred. Please try again later.',
  DISPLAY_NAME_INVALID: 'The display name entered is invalid.',
  TOO_MANY_REQUESTS: 'Too many requests. Please try again later after some time.',
  OPERATION_NOT_ALLOWED: 'The email/password accounts are not enabled.',
  WEAK_PASSWORD: 'The password is too weak.',
};

export default CONSTANTS;
